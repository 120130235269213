@import './variables.scss';
@import './components.scss';

* {
    /* reset default styles */
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
    /* set common styles */
    font-family: $general;
    font-size: inherit;
}

html {
    font-size: 20px;
    position: relative;
}
body {
    position: relative;
    overflow-x: hidden;
}

header,
main,
footer {
    transition: opacity 0.3s ease;
}

@media (max-width: $tablet) {
    html {
        font-size: 18px;
    }
}

@media (max-width: $phone) {
    html {
        font-size: 16px;
    }
}
