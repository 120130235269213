@import '../../styles/variables.scss';

.footer {
    background-color: $dark-grey;
}

.footer__inner {
    padding-bottom: 3rem;
}

/* bottom page logo */
.footer__logo {
    display: flex;
    justify-content: center;
    width: 8rem;
    margin: 0 auto -1rem;
    animation: blinkingLogo 5s linear infinite;
    transform: translateY(-150%);
    position: relative;
    z-index: 2;
}

/* copyright subsection */
.footer__copyright-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &::after {
        content: '';
        width: 100%;
        height: 3px;
        background-color: #fffced;
        position: absolute;
        top: calc(50% - 1.5px);
        left: 0;
        z-index: 1;
    }
}

.footer__copyright {
    padding: 0 1.25rem;
    background-color: $dark-grey;
    position: relative;
    z-index: 2;

    * {
        color: #fffced;
        font-size: 0.7rem;
        line-height: 120%;
    }
}

.footer__copyright-text {
    display: inline-block;
    margin-right: 1.25rem;
}

/* background running sctring */
.footer__running-string {
    animation-name: moveRunningStringFooter;
    transform: translateX(-50%);
    left: 50%;
}

/* FAQ button */
.footer__copyright-button {
    background-color: transparent;
    text-decoration: underline;
    transition: 0.3s ease;
    cursor: pointer;

    &:hover,
    &:focus {
        opacity: 0.7;
        text-decoration: none;
    }
}

.footerAccordion__wrapper{
    height: 0;
    transition: 0.5s linear;
    overflow: hidden;
}

/* footer accordion */
.footerAccordion {
    max-width: 80%;
    margin: 0 auto;
    padding: 2rem 0 5rem;
}

/* single accordion item */
.footerAccordion__item {
    margin-bottom: 2rem;
    overflow: hidden;
    transition: 0.3s ease;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }

    /* styles when item is shown */
    &.footerAccordion__item-active {
        .footerAccordion__item-bullet {
            &::after {
                transform: translate(-50%, -35%) scale(0);
            }
        }
    }
}

/* wrapper for a tilte and '+' icon */
.footerAccordion__item-top {
    display: flex;
    align-items: center;
    width: fit-content;
    transition: 0.3s ease;
    cursor: pointer;
    position: relative;

    &:hover {
        opacity: 0.7;
    }
}
/* '+' icon */
.footerAccordion__item-bullet {
    width: 1rem;
    height: 0.25rem;
    margin-right: 1rem;
    background-color: $red;
    position: relative;

    &::after {
        content: '';
        width: 0.25rem;
        height: 1rem;
        background-color: $red;
        transform: translate(-50%, -35%);
        transition: 0.3s ease;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: 1;
    }
}
/* accordion item title */
.footerAccordion__item-title {
    color: $light-red;
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
}

/* accordion item main text */
.footerAccordion__item-textWrapper {
    padding-top: 1.5rem;
    padding-left: 2rem;
    line-height: 135%;
    transition: 0.5s linear;
}
/* single text paragraph */
.footerAccordion__item-paragraph {
    margin-bottom: 1rem;
    color: #FFFCED;
    font-size: 0.9rem;

    &:last-child {
        margin-bottom: 0;
    }
}

/* ANIMATIONS */
@keyframes blinkingLogo {
    0%,
    100% {
        opacity: 1;
    }
    50% {
        opacity: 0.3;
    }
}

@keyframes moveRunningStringFooter {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        /* if there's a visual bug with strings connection, try to change this value */
        transform: translate3d(-104.5%, 0, 0);
    }
}

/* SCREEN ADAPTATION */
/* mobile phones */
@media (max-width: $phone) {
    .footer__logo {
        transform: translateY(-75%);
    }

    .footerAccordion{
        max-width: 90%;
    }
}
