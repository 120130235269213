@import '../../styles/variables.scss';

.mission {
    background-color: $dark-grey;
}

.mission__inner {
    padding-top: 6.5rem;
}

.mission__title {
    .title__bg {
        /* shadow's color */
        &::after {
            color: rgba($dark-grey, 1);
        }
    }
}

.mission__plan {
    display: grid;
    margin-top: 5rem;
    position: relative;
}

/* wrapper for the text in the subsection */
.mission__plan-textwrapper {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    flex-wrap: wrap;
    color: #fffced;
    font-weight: 600;
    z-index: 2;
}

/* top text */
.mission__plan-maintext {
    max-width: 85%;
    margin-top: 2.5rem;
    font-size: 1.75rem;
    line-height: 135%;
}

/* link to the WFP website */
.mission__plan-link {
    color: $light-red;
    transition: 0.3s ease;

    span {
        text-decoration: underline;
    }

    &:hover {
        opacity: 0.7;
    }
}

/* "Where we are" */
.mission__plan-progress {
    width: 40%;
    margin-bottom: 5rem;
    writing-mode: vertical-rl;
    transform: rotate(-180deg);
}

/* progressbar white line */
.mission__plan-progressbar {
    width: 290px;
    height: 8px;
    background-color: #fff;
    border-radius: 4px;
    transform: rotate(-90deg) translate(5px, -5px);
    transform-origin: right;
    position: relative;
}
/* progressbar red line */
.mission__plan-progressline {
    width: 75%;
    height: 8px;
    background-color: $light-red;
    border-radius: 4px;
    top: 0;
    right: 0;
    position: absolute;
    z-index: 1;
}

/* bottom text */
.mission__plan-secondarytext {
    max-width: 60%;
    margin: auto 0 5rem;
    font-size: 1.25rem;
    line-height: 135%;

    span {
        display: block;
        margin-bottom: 1rem;
    }
}

/* background dino picture */
.mission__plan-img {
    grid-column: 1;
    grid-row: 1;
    margin: auto auto 0;
    position: relative;
    z-index: 1;

    &.image-loaded {
        overflow: visible !important;
    }
}

/* background running sctring */
.mission__running-string {
    transform: translateX(-50%) translateY(-50%);
    position: absolute !important;
    top: calc(40%);
    left: 50%;

    .running-string-text {
        font-size: 10rem;
    }
}

/* Total Revenue Distributions subsection */
.distributions {
    margin-top: 9rem;
}

.distributions__title {
    color: #fffced;
    font-size: 2.5rem;
    line-height: 135%;
}

.distributions__chart-wrapper {
    padding: 5rem 0 10rem;
}

/* message for mobile devices */
.mission__chart-infomsg {
    display: none;
}

/* chart image */
.mission__chart-img {
    width: 40% !important;
    transform: translateX(-50%) scale(0.75);
    opacity: 0.25;
    transition: 0.5s ease-in-out;
    border-radius: 50% !important;
    aspect-ratio: 149.8 / 150.8;
    overflow: visible !important;
    position: relative;
    left: 50%;

    & > img {
        border-radius: 50%;
        position: relative;
        z-index: 2;
    }

    &::after {
        display: none;
    }
}

/* wrapper for the pie parts */
.mission__chart-img-pieces {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    z-index: 2;
}

/* images and collision for them */
.mission__chart-pie {
    transition: 0.3s ease;
    position: absolute;
    z-index: 1;

    img {
        width: 100%;
        height: 100%;
        transition: 0.3s ease;

        /* image with red dots */
        &.mission__chart-pie-alt {
            opacity: 0;
            top: -100%;
        }
    }

    /* positioning images and collision items */
    &:nth-child(1) {
        width: 39.5%;
        height: 49%;
        border-top-right-radius: 70px;
        border-bottom-left-radius: 200px;
        transform: translate(-96.5%, -99%);
        top: 50%;
        left: 50%;
    }

    &:nth-child(2) {
        width: 50%;
        height: 45%;
        border-top-left-radius: 80px;
        transform: translate(-0.1%, -71.5%);
        top: 50%;
    }

    &:nth-child(3) {
        width: 86.6%;
        height: 50%;
        border-top-right-radius: 130px;
        border-bottom-left-radius: 200px;
        transform: translate(2%, 0);
        top: 50%;
    }

    &:nth-child(4) {
        width: 61.5%;
        height: 82.5%;
        border-top-right-radius: 130px;
        transform: translate(-18%, 0.5%);
        left: 50%;
    }

    /* hover effect */
    &.mission__chart-pie-active {
        &:nth-child(1) {
            transform: translate(-102%, -106%);
        }

        &:nth-child(2) {
            transform: translate(-7%, -73%);
        }

        &:nth-child(3) {
            transform: translate(1%, 7%);
        }

        &:nth-child(4) {
            transform: translate(-13%, -2%);
        }

        img {
            /* show the image with red dots */
            &.mission__chart-pie-alt {
                opacity: 1;
            }
        }
    }
}

/* collision is an area where user hovers on to (items have the same class as images for positioning) */

.mission__chart-collision-item {
    background-color: transparent;
    position: absolute;
    z-index: 20;

    &:nth-child(1) {
        clip-path: polygon(69% 34%, 60% 17%, 41% 23%, 33% 27%, 25% 32%, 10% 41%, 16% 55%, 26% 62%, 92% 100%);
        top: 45%;
        left: 50%;
    }

    &:nth-child(2) {
        clip-path: polygon(6% 42%, 13% 27%, 23% 5%, 96% 71%, 8% 91%, 7% 74%, 4% 60%);
    }

    &:nth-child(3) {
        clip-path: polygon(2% 57%, 0 31%, 55% 1%, 98% 65%, 75% 93%, 46% 92%, 20% 76%);
    }

    &:nth-child(4) {
        clip-path: polygon(92% 73%, 96% 52%, 87% 35%, 77% 23%, 62% 11%, 41% 4%, 6% 3%, 21% 60%, 77% 93%);
    }
}

/* description for the pie part */
.distributions__chart-item {
    color: #fffced;
    position: absolute;
    z-index: 1;
}
/* wrapper for percentage and arrow */
.distributions__chart-item-top {
    position: relative;
    z-index: 1;
}
/* pointing arrow */
.distributions__chart-line {
    position: absolute;
    z-index: 1;
}
/* description text */
.distributions__chart-text {
    max-width: 12rem;
    font-family: $friary;
    font-size: 0.8rem;
    line-height: 120%;
    position: relative;
    z-index: 2;
}

/* positioning items */
#distributions__chart-item-1 {
    top: 0;
    left: -170px;

    .distributions__chart-line {
        width: 280px;
        top: 50%;
        left: 75px;
    }
}
#distributions__chart-item-2 {
    top: 150px;
    left: -220px;

    .distributions__chart-line {
        width: 220px;
        top: 50%;
        left: 75px;
    }
}
#distributions__chart-item-3 {
    top: 540px;
    left: -150px;

    .distributions__chart-line {
        width: 290px;
        transform: translateY(-100%);
        top: 50%;
        left: 75px;
    }
}
#distributions__chart-item-4 {
    top: 200px;
    right: -350px;

    .distributions__chart-line {
        width: 230px;
        transform: translateX(-100%) translateY(-100%);
        top: -1rem;
        left: 0;
    }
}

/* SCREEN ADAPTATION */
@media (max-width: 1200px) {
    #distributions__chart-item-3 {
        top: 450px;
    }

    #distributions__chart-item-4 {
        right: -300px;
    }
}

@media (max-width: $smallScreen) {
    .distributions__chart-wrapper {
        overflow: hidden;
    }
    #distributions__chart-item-2 {
        top: 100px;
    }
    #distributions__chart-item-3 {
        top: 350px;
    }
    #distributions__chart-item-4 {
        right: -240px;

        .distributions__chart-line {
            transform: translateX(-50%) translateY(-100%);
        }
    }
}
/* tablet */
@media (max-width: $tablet) {
    .mission__plan {
        margin-top: 3rem;
    }

    .mission__plan-progress {
        width: 100%;
        margin: 5rem 0;
        text-align: center;
        writing-mode: horizontal-tb;
        transform: rotate(0deg);
    }
    .mission__plan-progressbar {
        margin: 0 auto;
        transform: rotate(0deg) translate(0, 5px) scale(-1, -1);
        transform-origin: center;
    }

    .mission__plan-secondarytext {
        margin-left: auto;
    }

    .mission__chart-infomsg {
        display: block;
        margin-bottom: 2rem;
        color: #fffced;
        text-align: center;
    }

    .mission__chart-img {
        width: 60% !important;
    }

    .distributions__title {
        text-align: center;
    }

    .distributions__chart-wrapper {
        padding-bottom: 5rem;
    }

    .distributions__chart-item {
        width: 50px;
        height: 50px;
        z-index: 3;

        &:hover {
            width: auto;
            height: auto;

            .distributions__chart-text {
                background-color: rgba($black, 0.9);
                opacity: 1;
                visibility: visible;
                z-index: 1;
                transition: 0.3s ease;
            }
        }
    }

    .distributions__chart-line {
        display: none;
    }

    .distributions__chart-percentage {
        user-select: none;
    }

    .distributions__chart-text {
        max-width: 7rem;
        padding: 0.5rem;
        border-radius: 4px;
        opacity: 0;
        box-shadow: 0 0 15px 5px rgba($black, 0.7);
        visibility: hidden;
        z-index: -1;
        transform: translateX(-25%) translateY(1rem);
    }

    #distributions__chart-item-1 {
        top: 60px;
        left: 100px;
    }
    #distributions__chart-item-2 {
        top: 140px;
        left: 50px;
    }
    #distributions__chart-item-3 {
        top: 250px;
        left: 150px;
    }
    #distributions__chart-item-4 {
        top: 150px;
        left: 250px;
    }
}
/* mobile phones */
@media (max-width: $phone) {
    .mission__plan {
        margin-top: 2.5rem;
    }

    .mission__plan-maintext,
    .mission__plan-secondarytext {
        max-width: 100%;
        text-align: center;
    }

    .distributions {
        margin-top: 4.5rem;
    }

    .distributions__chart-wrapper {
        padding: 2.5rem 0;
    }

    .mission__chart-img {
        width: 90% !important;
    }

    #distributions__chart-item-1 {
        top: 50px;
        left: 100px;
    }
    #distributions__chart-item-2 {
        top: 160px;
        left: 60px;
    }
    #distributions__chart-item-3 {
        top: 280px;
        left: 150px;
    }
    #distributions__chart-item-4 {
        top: 150px;
        left: 270px;
    }
}

@media (max-width: 400px) {
    #distributions__chart-item-1 {
        top: 60px;
        left: 85px;
    }
    #distributions__chart-item-2 {
        top: 100px;
        left: 30px;
    }
    #distributions__chart-item-3 {
        top: 190px;
        left: 140px;
    }
    #distributions__chart-item-4 {
        top: 100px;
        left: 170px;
    }
}
