@import '../../styles/variables.scss';

.roadmap {
    background-color: $light-red;
}

.roadmap__inner {
    display: grid;
    grid-template-columns: 50% 60%;
    padding-top: 5rem;
    overflow: hidden;
}

/* main content */
.roadmap__main {
    grid-column: 1;
    padding-top: 6rem;
    padding-bottom: 1rem;
    position: relative;
    z-index: 2;
}

.roadmap__title {
    text-align: left;

    .title__bg {
        &::after {
            color: rgba($light-red, 1);
        }
    }
}

.roadmap__subtitle {
    text-align: left;
}

/* slides carousel */
.roadmap__swiper {
    /* when you add more text to the slides, set swiper's height to 450px */
    height: 225px;
    margin-top: 1.5rem;
}

/* carousel pagination */
.roadmap__swiper-pagination {
    width: 50%;
    left: 0;

    /* pagination buttons */
    .swiper-pagination-bullet {
        width: 3px !important;
        /* when you add more text to the slides, set bullet's height to 83px and margin-bottom to 20px */
        height: 41px !important;
        margin-bottom: 10px !important;
        background-color: rgba(255, 252, 237, 0.5) !important;
        border-radius: 12px !important;
        transition: 0.3s ease;

        &:hover,
        &:focus {
            background-color: rgba(255, 252, 237, 0.9) !important;
        }

        &-active {
            background-color: #fffced !important;
        }

        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}

.swiper-slide {
    width: 100% !important;
    padding: 0 2.5rem;
    background: transparent;

    .os-scrollbar {
        width: 11px;
    }
    .os-scrollbar-track,
    .os-scrollbar-handle {
        width: 9px;
    }
    .os-scrollbar-handle {
        cursor: pointer;
    }
}

/* slide number */
.roadmap__swiper-number {
    color: $grey;
    @supports (-webkit-text-stroke: 1px #ffbeb4) {
        & {
            color: transparent;
            -webkit-text-stroke: 1px #ffbeb4;
        }
    }
    font-family: $wavesun;
    font-size: 7rem;
    font-weight: 400;
    line-height: 1;
}

/* slide's text block */
.roadmap__swiper-paragraph {
    margin-bottom: 1rem;
    color: #fffced;
    font-family: $friary;
    font-size: 0.8rem;
    line-height: 120%;

    &:last-child {
        margin-bottom: 0;
    }
}

/* dino background picture */
.roadmap__dino {
    grid-column: 2;
    align-self: end;
    position: relative;
    left: -20%;
    z-index: 1;

    /* hide all of the images */
    .image {
        display: block;
        opacity: 0;
        transition: 0.3s ease;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    /* show the active image */
    .roadmap__dino-active {
        opacity: 1;
        position: relative;
        z-index: 1;
    }
}

/* SCREEN ADAPTATION */
/* tablet */
@media (max-width: $tablet) {
    .roadmap__inner {
        grid-template-columns: 80% 60%;
        padding-top: 0;
    }
    .roadmap__dino {
        left: -70%;
    }
}
/* mobile phones */
@media (max-width: $phone) {
    .roadmap__inner {
        grid-template-columns: 90% 60%;
    }
    .roadmap__dino {
        left: -90%;
    }
    .roadmap__swiper-pagination {
        .swiper-pagination-bullet {
            /* make a buttons wider so it will be easier for user to tap on them */
            width: 9px !important;
        }
    }
}
