/* fonts */
$friary: 'Friary Pro', 'Courier New', monospace;
$gasmask: 'Gas Mask Warriors';
$horizon: 'Horizon', 'Verdana', sans-serif;
$general: 'General Sans', 'Verdana', sans-serif;
$wavesun: 'WAVESUN';

/* colors */
$black: rgb(17, 17, 17);
$dark-grey: rgb(22, 22, 22);
$grey: rgb(28, 28, 28);
$light-red: rgb(254, 47, 47);
$red: rgb(187, 0, 14);

/* screen sizes */
$smallScreen: 992px;
$tablet: 768px;
$phone: 576px;
