@import './variables.scss';

.container {
    max-width: 1270px;
    margin: 0 auto;
    padding: 0 15px;
}

.no-scroll {
    overflow: hidden !important;
}

/* section title */
/* html code example:
<div class="title">
    <span class="title__bg">Mission</span>
    <h2 class="title__text">Our Mission</h2>
</div>
*/
.title {
    margin-bottom: 1rem;
    text-align: center;
    position: relative;
}

.title__text {
    color: #fffced;
    font-family: $general;
    font-size: 2.5rem;
    line-height: 210%;
    text-transform: uppercase;
    position: relative;
    z-index: 3;
}

.title__bg {
    color: $red;
    @supports (-webkit-text-stroke: 1px #aba89e) {
        & {
            color: transparent;
            -webkit-text-stroke: 1px #aba89e;
        }
    }
    font-family: $gasmask;
    font-size: 6.4rem;
    font-weight: 400;
    line-height: 120%;
    position: absolute;
    bottom: 0;
    left: 1rem;
    z-index: -1;

    &::after {
        content: '';
        width: 100%;
        height: 0;
        box-shadow: 0 -20px 50px 25px;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
    }
}

/* section subtitle */
.subtitle {
    color: #fffced;
    font-family: $friary;
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 120%;
    text-align: center;

    span {
        all: inherit;
    }
}

/* infinite string */
/* html code example: 
<div class="running-string-wrapper">
    <div class="running-string">
        <p class="running-string-text">
            Text text text
        </p>
        <p class="running-string-text">
            Text text text
        </p>
    </div>
</div> */
.running-string-wrapper {
    width: 100vw;
    overflow: hidden;
    position: relative;

    &::after {
        content: '';
        width: 100%;
        height: 0;
        box-shadow: 0 -10px 50px 45px $dark-grey;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
    }
}

.running-string {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    position: relative;
    z-index: 1;
}

.running-string-text {
    display: inline-block;
    margin-right: 3rem;
    font-family: $wavesun;
    font-size: 6rem;
    line-height: 95%;
    animation: moveRunningString 20s linear infinite;

    color: #fffced;
    @supports (-webkit-text-stroke: 1px #fffced) {
        & {
            color: transparent;
            -webkit-text-stroke: 1px #fffced;
        }
    }
}

/* ANIMATIONS */
@keyframes moveRunningString {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        /* if there's a visual bug with strings connection, try to change this value */
        transform: translate3d(-104%, 0, 0);
    }
}

/* SCREEN ADAPTATION */
/* tablet */
@media (max-width: $tablet) {
    .container {
        padding: 0 10px;
    }
}

/* mobile phones */
@media (max-width: $phone) {
    .container {
        padding: 0 5px;
    }
    .title__bg {
        font-size: 6rem;
    }
}
