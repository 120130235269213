@import '../../styles/variables.scss';

.intro {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
    background-color: $dark-grey;
    overflow: hidden;

    .container {
        grid-column: 1 / span 1;
        grid-row: 1 / 1;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
    }
}

/* background image */
.intro__background {
    display: flex;
    justify-content: center;
    grid-column: 1 / span 1;
    grid-row: 1 / 1;
    max-width: fit-content;
    margin: 0 auto;
    animation: moveBackgroundImage 30s linear 1s infinite;
    position: relative;
    left: 0;
    z-index: 1;

    /* prevent images from selecting */
    img {
        pointer-events: none;
    }

    .intro__background-mainpic {
        min-width: fit-content;
        height: 100vh;
        min-height: 750px;
        max-height: 1000px;
        position: relative;
        z-index: 1;
        aspect-ratio: 29.75 / 10.82;
    }
}

/* class for sparks */
.intro__bg-flame {
    background: linear-gradient(to bottom, rgba(147, 37, 38, 0.5), rgba(171, 141, 7, 0.5));
    clip-path: polygon(0 24%, 11% 3%, 33% 88%, 20% 100%);
    animation: moveBackgroundFlame 5s linear infinite;
    position: absolute;
    bottom: 0;

    &.flame-reverse {
        clip-path: polygon(0 0, 18% 6%, 30% 88%, 18% 88%);
    }
}

/* group of sparks in the left side */
.intro__background-flames-left {
    display: none;
    width: 650px;
    transform: translateX(-500px);
    position: absolute;
    bottom: 0;
    z-index: 2;
}
#intro__bg-flame-1 {
    width: 6rem;
    height: 6rem;
    animation-delay: 2s;
    right: 50px;
}
#intro__bg-flame-2 {
    width: 2.5rem;
    height: 2.5rem;
    animation-delay: 4s;
    right: 70px;
}
#intro__bg-flame-3 {
    width: 2.5rem;
    height: 2.5rem;
    animation-delay: 3s;
    right: 50px;
}
#intro__bg-flame-4 {
    width: 1.5rem;
    height: 1.5rem;
    animation-delay: 4.5s;
    right: 30px;
}
#intro__bg-flame-5 {
    width: 3rem;
    height: 3rem;
    animation-delay: 1s;
    left: 290px;
}
#intro__bg-flame-6 {
    width: 2rem;
    height: 2rem;
    animation-delay: 2.5s;
    left: 270px;
}

/* group of sparks in the right side */
.intro__background-flames-right {
    display: none;
    width: 200px;
    transform: translateX(-50%);
    position: absolute;
    bottom: 85px;
    left: 50%;
    z-index: 2;
}
#intro__bg-flame-7 {
    width: 3rem;
    height: 3rem;
    animation-delay: 2.5s;
    left: 10px;
}
#intro__bg-flame-8 {
    width: 2rem;
    height: 2rem;
    animation-delay: 4s;
    left: 40px;
}
#intro__bg-flame-9 {
    width: 2rem;
    height: 2rem;
    animation-delay: 1.5s;
    right: 40px;
}
#intro__bg-flame-10 {
    width: 4rem;
    height: 4rem;
    animation-delay: 0.5s;
    left: 70px;
    bottom: -10px;
}

/* main content */
.intro__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;

    /* gradient background */
    &::after {
        content: '';
        width: 100%;
        height: 200%;
        background: radial-gradient(circle, rgba($light-red, 0.5) 0%, transparent 50%);
        transform: translateY(-25%);
        position: absolute;
        top: 0;
        right: -40%;
        z-index: 1;
    }
}

.intro__title {
    margin-top: auto;

    .title__text {
        display: inline-block;
        font-family: $horizon;
        transform: translateY(-500%);
        opacity: 0.25;
        transition: 1s ease-out;

        &:nth-child(1) {
            .title__bg {
                transform: translateX(-3rem);
            }
        }

        &:nth-child(2) {
            .title__bg {
                transform: translateX(-.6rem);
            }
        }

        &:nth-child(3) {
            .title__bg {
                transform: translateX(1.75rem);
            }
        }

        &.show-title {
            opacity: 1;

            &:nth-child(1) {
                transform: translateX(-1rem) translateY(0);
            }

            &:nth-child(2) {
                transform: translateX(0) translateY(0);
            }

            &:nth-child(3) {
                transform: translateX(1rem) translateY(0);
            }
        }
    }

    /* shadow color */
    .title__bg {
        &::after {
            color: rgba(0, 0, 0, 0.75);
        }
    }
}

.intro__subtitle {
    transform: translateY(-300%);
    opacity: 0;
    transition: 1s ease-out;
    position: relative;
    z-index: 2;
}

/* wrapper for the mouse icon and button to play the music theme */
.intro__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: auto;
    margin-bottom: 1rem;
    position: relative;
    z-index: 2;
}

/* button to play the music theme */
.intro__audio{
    width: 100%;
    pointer-events: none;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;

    .container{
        display: flex;
        align-items: flex-end;
        padding-bottom: 1rem;
    }
}

.intro__audio-play {
    display: block;
    width: 8rem;
    background-color: transparent;
    cursor: pointer;
    transition: 0.3s ease;
    transform-origin: bottom left;
    opacity: 0.95;
    pointer-events: auto;
    position: relative;
    z-index: 100;

    &:hover {
        opacity: 1;
        transform: scale(1.1);
    }

    img {
        position: relative;
        z-index: 1;
    }
}
/* button's play icon */
.intro__audio-play-icon {
    opacity: 0;
    transition: 0.3s ease;
    transform: translateY(-40%);
    position: absolute;
    top: 50%;
    right: 10px;
    z-index: 2;
}

/* mouse icon */
.intro__scroll {
    text-align: center;
    position: relative;

    img {
        margin: 0 auto;

        &:nth-child(2) {
            animation: moveMouseWheel 5s linear infinite 2s;
            position: absolute;
            top: 5px;
            left: calc(50% - 1px);
        }
    }

    .intro__scroll-text {
        color: #fffced;
        font-size: 0.7rem;
    }
}

/* ANIMATIONS */
@keyframes moveBackgroundImage {
    0%,
    100% {
        transform: translate3d(0, 0, 0);
    }
    20% {
        transform: translate3d(-250px, 0, 0);
    }
    75% {
        transform: translate3d(200px, 0, 0);
    }
}
/* tablet version */
@keyframes moveBackgroundImageTablet {
    0%,
    100% {
        transform: translate3d(0, 0, 0);
    }
    20% {
        transform: translate3d(-450px, 0, 0);
    }
    75% {
        transform: translate3d(600px, 0, 0);
    }
}
/* mobile phone version */
@keyframes moveBackgroundImagePhone {
    0%,
    100% {
        transform: translate3d(0, 0, 0);
    }
    20% {
        transform: translate3d(-625px, 0, 0);
    }
    75% {
        transform: translate3d(850px, 0, 0);
    }
}

/* animation for sparks */
@keyframes moveBackgroundFlame {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    60% {
        opacity: 0;
        transform: translateY(-100px);
    }
    95% {
        opacity: 0;
        transform: translateY(0);
    }
    100% {
        opacity: 1;
    }
}

/* animation for mouse icon */
@keyframes moveMouseWheel {
    0%,
    25%,
    50%,
    100% {
        transform: translateY(0);
    }
    12%,
    17%,
    37%,
    42% {
        transform: translateY(3px);
    }
}

/* SCREEN ADAPTATION */
@media (min-width: 2000px) {
    .intro__background {
        animation-name: none;
    }
    .intro__background-flames-right {
        bottom: 120px;
    }
}

@media (min-width: 1500px) {
    .intro__background-flames-right {
        bottom: 100px;
    }
}

@media (max-width: 1200px) {
    .intro__title {
        .title__text {
            font-size: 2.25rem;

            .title__bg{
                font-size: 5.75rem;
            }

            &:nth-child(2) {
                .title__bg {
                    transform: translateX(-.9rem);
                }
            }

            &:nth-child(3) {
                .title__bg {
                    transform: translateX(0);
                }
            }
        }
    }
}

@media (max-width: $smallScreen) {
    .intro__title {
        padding-top: 4rem;

        .title__text {
            display: block;
            font-size: 2.5rem;
            line-height: 250%;

            .title__bg{
                bottom: 1rem;
                letter-spacing: 8px;
            }

            &:nth-child(1) {
                .title__bg {
                    transform: translateX(-.5rem);
                }
            }

            &:nth-child(2) {
                .title__bg {
                    transform: translateX(-1rem);
                }
            }

            &:nth-child(3) {
                .title__bg {
                    transform: translateX(.5rem);
                }
            }

            &.show-title {
                &:nth-child(1) {
                    transform: translateX(0) translateY(0);
                }
                &:nth-child(3) {
                    transform: translateX(0) translateY(0);
                }
            }
        }
    }
}

/* tablet */
@media (max-width: $tablet) {
    .intro__background {
        animation-name: moveBackgroundImageTablet;
        animation-duration: 40s;
    }

    .intro__inner {
        &::after {
            background: radial-gradient(circle, rgba($light-red, 0.5) 0%, transparent 40%);
            top: -30%;
        }
    }

    .intro__title {
        .title__text {
            display: block;
            line-height: 250%;

            &.show-title {
                &:nth-child(1) {
                    transform: translateX(0) translateY(0);
                }
                &:nth-child(3) {
                    transform: translateX(0) translateY(0);
                }
            }
        }
    }
    .intro__bottom {
        justify-content: center;
    }
    .intro__scroll {
        width: 100%;
        margin-right: 0;
        transform: none;
    }
}
/* mobile phone */
@media (max-width: $phone) {
    .intro__background {
        animation-name: moveBackgroundImagePhone;
        animation-duration: 50s;
    }

    .intro__inner {
        &::after {
            width: 200%;
            height: 200%;
            background: radial-gradient(circle, rgba($light-red, 0.5) 0%, transparent 40%);
            right: -60%;
            top: -30%;
        }
    }

    .intro__title {
        .title__text {
            .title__bg{
                font-size: 5.5rem;
            }
        }
    }

    .intro__audio{
        bottom: 1rem;
    }
}
