@import '../../styles/variables.scss';

.backstory {
    background-color: $dark-grey;
    overflow: hidden;
}

.backstory__inner {
    padding-top: 10rem;
    position: relative;

    /* gradient background */
    &::after {
        content: '';
        width: 100%;
        height: 100%;
        background: radial-gradient(circle, rgba(254, 47, 47, 0.5) 0%, rgba(22, 22, 22, 1) 75%);
        transform: translate(-75%, -50%);
        position: absolute;
        right: -100%;
        bottom: -100%;
        z-index: 1;
    }
}

.backstory__title {
    .title__bg {
        &:nth-child(1) {
            left: -3rem;
        }

        &:nth-child(2) {
            left: 7rem;
        }

        /* shadow's color */
        &::after {
            color: rgba($dark-grey, 1);
        }
    }
}

/* main content */
.backstory__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: calc(262px + 7.5rem) auto;
    position: relative;
    z-index: 2;
}

.backstory__gallery-wrapper {
    grid-column: 1 / span 2;
    grid-row: 1;
    margin-top: 7.5rem;
    border-radius: 8px;
    overflow: hidden;
}
/* images gallery */
.backstory__gallery {
    display: flex;
    flex-wrap: nowrap;
    width: calc((262px + 1rem) * 69);
    animation: moveGallery 180s linear 2s infinite;

    &:hover {
        animation-play-state: paused !important;
    }
}

/* single gallery item */
.backstory__gallery-link {
    flex-shrink: 0;
    margin-right: 1rem;

    &:last-child {
        margin-right: 0;
    }
}

/* large dino picture */
.backstory__dinopic {
    grid-column: 1;
    grid-row: 1 / span 2;
    align-self: end;
    padding-top: 5rem;

    .image {
        display: block;
        transform: scale(1.2);
        left: -10%;
    }
}

/* section with a description text */
.backstory__description {
    align-self: end;
    padding: 3rem 0 2rem 1rem;
}

/* big letters title */
.backstory__description-title {
    margin-bottom: 0.5rem;
    color: #fffced;
    font-family: $wavesun;
    font-size: 4.5rem;
    text-transform: uppercase;

    @supports (-webkit-text-stroke: 1px #c4b6ac) {
        & {
            color: transparent;
            -webkit-text-stroke: 1px #c4b6ac;
        }
    }
}

.backstory__description-text {
    max-width: 90%;
    margin-bottom: 1.5rem;
    color: #fffced;
    font-family: $friary;
    font-size: 0.8rem;
    line-height: 120%;

    &:last-child {
        margin-bottom: 0;
    }

    /* wrapper for the text and the line */
    .backstory__description-animation {
        all: inherit;
        display: block;
        position: relative;

        /* text with a moving line below it */
        .backstory__description-animation-text {
            all: inherit;
            display: inline-block;
            width: fit-content;
            margin-top: 2rem;
            font-size: 1rem;
        }

        /* moving red line */
        .backstory__description-animation-line {
            width: 2rem;
            height: 5px;
            background-color: $light-red;
            position: absolute;
            left: 0;
            bottom: -15px;
            z-index: 1;
        }
    }
}

/* ANIMATIONS */
@keyframes moveGallery {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(calc(-100% + 270px));
    }
}

/* SCREEN ADAPTATION */
/* tablet */
@media (max-width: $tablet) {
    .backstory__inner {
        padding-top: 7.5rem;
    }
    .backstory__main {
        grid-template-columns: 40% 60%;
    }
    .backstory__gallery-wrapper {
        margin-top: 5rem;
    }
}
/* mobile phones */
@media (max-width: $phone) {
    .backstory__main {
        grid-template-columns: 50% 90%;
    }

    .backstory__dinopic {
        position: relative;
        z-index: 1;
    }
    .backstory__description {
        background-color: rgba($dark-grey, 0.75);
        padding-top: 3rem;
        position: relative;
        left: -50%;
        z-index: 2;
    }
}
