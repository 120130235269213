@import '../../styles/variables.scss';

.image {
    display: inline-block;
    width: fit-content;
    max-width: 100%;
    height: fit-content;
    background-color: rgba($light-red, 0.25);
    border: 3px solid $light-red;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba($light-red, 0.5);
    overflow: hidden;
    position: relative;

    /* camera icon */
    &::before {
        /* content: url('./img/image-placeholder.svg'); */
        width: 100px;
        height: 100px;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
    }

    /* moving line */
    &::after {
        content: '';
        width: 30px;
        height: 120%;
        background: linear-gradient(180deg, rgba($red, 0.25), rgba($light-red, 0.75));
        transform: rotate(15deg);
        animation: lazyImage 5s linear infinite;
        position: absolute;
        top: -10%;
        z-index: 3;
    }

    &.image-loaded {
        background-color: transparent;
        border: none;
        box-shadow: none;

        &::before,
        &::after {
            display: none;
        }

        img {
            opacity: 1;
        }

        canvas {
            display: none;
        }
    }

    img {
        transition: 0.3s ease;
        opacity: 0;
    }
}

img {
    display: block;
    max-width: 100%;
    height: auto;
    position: relative;
    z-index: 5;
}

@keyframes lazyImage {
    0% {
        left: -10%;
    }
    50% {
        left: 120%;
    }
    100% {
        left: 120%;
    }
}
