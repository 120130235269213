@import '../../styles/variables.scss';

.formSection {
    width: 100vw;
    overflow: hidden;
    background-color: $dark-grey;
}

.formSection__inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding-top: 10rem;
}

/* dino pictures */
.formSection__background {
    grid-column: 1;
    grid-row: 1;
    align-self: end;
    display: flex;
    justify-content: space-between;
    z-index: 1;

    .formSection__dino {
        position: relative;

        &:nth-child(1) {
            left: -10%;
        }

        &:nth-child(2) {
            margin-top: -2rem;
            right: -10%;
        }
    }
}

/* main content */
.formSection__content {
    grid-column: 1;
    grid-row: 1;
    align-self: end;
    padding-bottom: 7rem;
    z-index: 2;
}

.formSection__title {
    .title__bg {
        left: 2rem;

        &::after {
            color: rgba($dark-grey, 1);
        }
    }
}

.formSection__subtitle {
    max-width: 50%;
    margin: 0 auto;
}

.form {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: 2.5rem auto 0;
}
/* common styles */
.form__elem {
    margin-bottom: 0.75rem;
    padding: 1rem 1.5rem;
    border-radius: 12px;
    font-family: $friary;
    font-size: 0.8rem;
    line-height: 120%;
    transition: 0.5s ease;
    outline: none;

    &:last-child {
        margin-bottom: 0;
    }

    &::placeholder {
        text-transform: uppercase;
    }
}
/* input element */
.form__input {
    color: #fffced;
    background-color: transparent;
    border: 1px solid #fffced;

    &:hover,
    &:focus {
        border-color: $light-red;
    }
}
/* submit button */
.form__submit {
    color: $dark-grey;
    background-color: #fffced;
    text-transform: uppercase;
    cursor: pointer;

    &:hover,
    &:disabled,
    &:focus {
        background-color: $light-red;
    }

    &:disabled {
        opacity: 0.2;
    }
}

/* SCREEN ADAPTATION */
/* tablet */
@media (max-width: $tablet) {
    .form {
        width: 60%;
    }
}
/* mobile phones */
@media (max-width: $phone) {
    .formSection__title {
        margin-bottom: 0;

        .title__bg {
            left: 3rem;
        }
    }

    .formSection__subtitle {
        max-width: 90%;
    }

    .formSection__content {
        padding-bottom: 4rem;
    }

    .form {
        width: 80%;
    }
    .form__input {
        background-color: rgba($dark-grey, 0.5);
    }
}

@media (max-width: 495px) {
    .formSection__title {
        .title__text {
            top: -3rem;
        }

        .title__bg {
            left: -2.5rem;
            bottom: -3rem;
        }
    }
}
