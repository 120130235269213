@import '../../styles/variables.scss';

$default: #ebebeb;
$success: #a8f0c6;
$danger: #f7a7a3;
$warning: #ffd48a;
$info: #2ff8fe;

.alert {
  width: 90%;
  max-width: 600px;
  margin: 20px auto;
  padding: 10px 10px;
  // padding: 15px 30px;
  padding-right: 34px;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 15px 5px #222;

  position: fixed;
  top: 54px;
  right: 20px;
  z-index: 99;
  opacity: 0;
  transition: 2s ease;
  display: flex;
  align-items: center;
  color: #fff;

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 24px;
    height: 24px;
    opacity: 0.5;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    right: 10px;
    top: 10px;
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;
    cursor: pointer;
  }

  &__text {
    font-family: $friary;
    font-size: 0.8rem;
  }

  &__icon {
    font-size: 1.0rem;
    margin-right: 10px;
  }
}

.alert--default,
.alert--success,
.alert--error,
.alert--info,
.alert--warning,
.alert--demonic {
  $accentColor: darken($light-red, 50);
  background-color: $light-red;
  border-left: 20px solid $accentColor;

  .close {
    border-color: $accentColor;
    color: $accentColor;
  }
}
