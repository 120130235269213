@import '../../styles/variables.scss';

.header {
    width: 100vw;
    background-color: rgba($dark-grey, 0.95);

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        & {
            background-color: transparent;
            backdrop-filter: blur(10px);
        }
    }

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 90;
}

.header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem 0;
    position: relative;
}

.header__logo {
    width: 5.5rem;
    margin-right: 1rem;
    position: relative;
    z-index: 1;
}

/* information about which section is currently in user's viewport */
.header__menu-activeSessionInfo {
    display: none;
}

/* navigation burger button */
.header__menu-btn {
    display: none !important;
    position: relative;
    z-index: 20;

    .hamburger-box {
        span,
        span::before,
        span::after {
            background-color: $red;
        }
    }

    &.is-active {
        .hamburger-box {
            span,
            span::before,
            span::after {
                background-color: $red;
            }
        }
    }
}

/* links list */
.header__nav {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    row-gap: 1rem;
    position: relative;
    z-index: 2;
}

/* navigation link */
.header__nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-right: 1rem;
    padding: 0.5rem 0.75rem;
    color: #fff;
    background-color: rgba(255, 252, 237, 0.2);
    border-radius: 4px;
    font-family: $friary;
    font-size: 0.7rem;
    line-height: 120%;
    transition: 0.3s ease;
    outline: none;

    /* secondary styles link */
    &.header__nav-link-secondary {
        background-color: $light-red;

        &:hover {
            color: $red;
        }
    }

    /* info styles - Account Id link */
    &.header__nav-link-info {
        background-color: #fff;
        color: $black;
    }
    
    /* transition for svg icons */
    path {
        transition: 0.3s ease;
    }

    /* add active styles */
    &:hover,
    &:focus,
    &.link-active {
        color: $black;
        background-color: #fff;

        /* change SVG icons color */
        .header__nav-link-svg path {
            fill: $black;
        }

        .header__nav-link-opensea {
            /* circle background color */
            path:nth-child(1) {
                fill: $black;
            }
            /* boat color */
            path:nth-child(2),
            path:nth-child(3) {
                fill: $light-red;
            }
        }
    }

    /* add a "border" on focus */
    &:focus {
        box-shadow: 0 0 0 3px $red;
    }
}

/* SCREEN ADAPTATION */
/* tablet */
@media (max-width: $tablet) {
    .header__inner {
        padding: 0.25rem 0;
    }

    /* information about which section is currently in user's viewport */
    .header__menu-activeSessionInfo {
        display: block;
        color: #fff;
        font-family: $friary;
        font-size: 0.9rem;
        line-height: 120%;
        transform: translate(-50%, -50%);
        transition: 0.3s ease;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
    }

    .header__menu-btn {
        display: inline-block !important;
    }

    .header__nav {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100vw;
        height: 100vh;
        padding: 1rem;
        transform: translateY(-100%);
        transition: 0.5s ease-out;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;

        &::before {
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba($dark-grey, 0.95);
            /* uncomment this when client provides high-quality version of the logotype
            background-image: url('../../images/logo.svg'); */
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            transform: translateY(300%);
            transition: 0.5s ease-out;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
        }

        &.nav-active {
            transform: translateY(0);

            &::before {
                transform: translateY(0);
            }
        }
    }

    .header__nav-link {
        width: 50vw;
        margin-right: 0;
        background-color: $grey;
        font-size: 1rem;
        position: relative;
        z-index: 2;
    }
}

/* mobile phones */
@media (max-width: $phone) {
    .header {
        @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            & {
                background-color: rgba($dark-grey, 0.95);
                backdrop-filter: none;
            }
        }
    }
    .header__nav-link {
        width: 75vw;
    }
}
