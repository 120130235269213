/* Friary Pro */

@font-face {
    font-family: 'Friary Pro';
    src: url('./FriaryPro/FriaryPro.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

/* Gas Mask Warriors */

@font-face {
    font-family: 'Gas Mask Warriors';
    src: url('./GasMaskWarriors/GasMaskWarriors.ttf') format('truetype'), url('./GasMaskWarriors/GasMaskWarriors.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

/* Horizon */

@font-face {
    font-family: 'Horizon';
    src: url('./Horizon/TrueType/horizon.ttf') format('truetype'), url('./Horizon/OpenType/horizon.otf') format('opentype'), url('./Horizon/WOFF/horizon.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Horizon Outlined';
    src: url('./Horizon/TrueType/horizon_outlined.ttf') format('truetype'), url('./Horizon/OpenType/horizon_outlined.otf') format('opentype'),
        url('./Horizon/WOFF/horizon_outlined.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

/* General Sans */

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans/GeneralSans-Extralight.woff2') format('woff2'), url('./GeneralSans/GeneralSans-Extralight.woff') format('woff'),
        url('./GeneralSans/GeneralSans-Extralight.ttf') format('truetype');
    font-weight: 200;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans/GeneralSans-ExtralightItalic.woff2') format('woff2'), url('./GeneralSans/GeneralSans-ExtralightItalic.woff') format('woff'),
        url('./GeneralSans/GeneralSans-ExtralightItalic.ttf') format('truetype');
    font-weight: 200;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans/GeneralSans-Light.woff2') format('woff2'), url('./GeneralSans/GeneralSans-Light.woff') format('woff'), url('./GeneralSans/GeneralSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans/GeneralSans-LightItalic.woff2') format('woff2'), url('./GeneralSans/GeneralSans-LightItalic.woff') format('woff'),
        url('./GeneralSans/GeneralSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans/GeneralSans-Regular.woff2') format('woff2'), url('./GeneralSans/GeneralSans-Regular.woff') format('woff'), url('./GeneralSans/GeneralSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans/GeneralSans-Italic.woff2') format('woff2'), url('./GeneralSans/GeneralSans-Italic.woff') format('woff'), url('./GeneralSans/GeneralSans-Italic.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans/GeneralSans-Medium.woff2') format('woff2'), url('./GeneralSans/GeneralSans-Medium.woff') format('woff'), url('./GeneralSans/GeneralSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans/GeneralSans-MediumItalic.woff2') format('woff2'), url('./GeneralSans/GeneralSans-MediumItalic.woff') format('woff'),
        url('./GeneralSans/GeneralSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans/GeneralSans-Semibold.woff2') format('woff2'), url('./GeneralSans/GeneralSans-Semibold.woff') format('woff'),
        url('./GeneralSans/GeneralSans-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans/GeneralSans-SemiboldItalic.woff2') format('woff2'), url('./GeneralSans/GeneralSans-SemiboldItalic.woff') format('woff'),
        url('./GeneralSans/GeneralSans-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans/GeneralSans-Bold.woff2') format('woff2'), url('./GeneralSans/GeneralSans-Bold.woff') format('woff'), url('./GeneralSans/GeneralSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'General Sans';
    src: url('./GeneralSans/GeneralSans-BoldItalic.woff2') format('woff2'), url('./GeneralSans/GeneralSans-BoldItalic.woff') format('woff'),
        url('./GeneralSans/GeneralSans-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
}

/* WAVESUN */

@font-face {
    font-family: 'WAVESUN';
    src: url('./WAVESUN/WAVESUN.ttf') format('truetype'), url('./WAVESUN/WAVESUN.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}
