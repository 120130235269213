@import '../../styles/variables.scss';

.team {
    background-color: $light-red;
}

.team__inner {
    padding: 10rem 0 12rem;
}

.team__title {
    margin-bottom: 0;
    text-align: left;

    .title__bg {
        &::after {
            color: rgba($light-red, 1);
        }
    }
}

/* wrapper for team members cards */
.team__items-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.5rem;
}

/* team member card */
.team__item {
    display: flex;
    flex-direction: column;
    width: 33.333%;
    padding: 0.75rem 1.5rem;
    border: {
        top: 1px solid #fffced;
        left: 1px solid #fffced;
        bottom: 1px solid #fffced;
    }

    &:last-child {
        border-right: 1px solid #fffced;
    }

    * {
        color: #fffced;
        font-family: $friary;
        font-weight: 400;
    }
}
/* team member picture */
.team__item-pic {
    margin-bottom: 1.5rem;
    border-radius: 0 !important;
}
/* team member name */
.team__item-title {
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
    line-height: 120%;

    span {
        display: block;

        /* post in DDD */
        &:nth-child(2){
            text-transform: uppercase;
        }
    }
}

.team__item-text {
    margin-bottom: 1.5rem;
    font-size: 0.8rem;
    line-height: 120%;
}

.team__item-link {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-top: auto;
    font-size: 0.8rem;
    line-height: 120%;
    transition: 0.3s ease;
    position: relative;

    &:hover {
        opacity: 0.7;
    }

    span {
        margin-right: 0.5rem;
        text-transform: uppercase;
    }
}

/* SCREEN ADAPTATION */
@media (max-width: $smallScreen) {
    .team__items-wrapper {
        justify-content: center;
        gap: 1.5rem;
    }
    .team__item {
        width: 40%;
        border-right: 1px solid #fffced;
    }
}

/* mobile phones */
@media (max-width: $phone) {
    .team__inner{
        padding: 6rem 0;
    }
    .team__title {
        .title__bg {
            font-size: 5.75rem;
        }
    }
    .team__items-wrapper {
        gap: 0;
    }
    .team__item {
        width: 100%;
        border-top: none;

        &:first-child {
            border-top: 1px solid #fffced;
        }
    }
}
